import * as React from "react";
import {
  Wrapper,
  PdpWrapper,
  DescriptionWrapper,
ArtistDescription,
  Citations,
  PhotosWrapper,
  CardWrapper,
  ProfilWrapper,
  NameWrapper,
  CarrouselWrapper2,
  LinkWrapper,
  ButtonWrapper,
  TitleWrapper,
  ArtistInfos,
  ImageWrapper,
  MobileProtrait,
  MoreButton,
  More,
} from "./Rammellzee.module.css";
import Title from "./../../../../components/Title";
import Carousel from 'react-multi-carousel';
import RammellzeePng from "../../../../res/rammellzee/portrait.jpg"
import RammellzeePng2 from "../../../../res/Photos site/Rammellzee/Rammellzee-1.jpg"
import RammellzeePng3 from "../../../../res/Photos site/Rammellzee/rammellzee-in-studio-Crown point press.jpg"
import RammellzeePng4 from "../../../../res/Photos site/Rammellzee/rammellzee.png"
import RammellzeePng5 from "../../../../res/Photos site/Rammellzee/Rammellzee4a-thumb-620x741-36455.jpg"
import Button from "./../../../../components/Button";
import PastPresent1 from "../../../../res/rammellzee/past-present-1.jpg"
import PastPresent2 from "../../../../res/rammellzee/past-present-2.jpg"
import PastPresent3 from "../../../../res/rammellzee/past-present-3.jpg"
import { Helmet } from "react-helmet";

const isBrowser = typeof window !== "undefined";
const infos ={
  path: "rammellzee",
  name: "Rammellzee",
  description:"Né à Far Rockaway, dans l'État de New York, Rammellzee a commencé à peindre des graffitis sur les wagons de métro à New York lorsqu'il était adolescent. Déjà fasciné par le pouvoir de l'alphabet, il a travaillé sur plusieurs supports pour étudier l'utilisation des lettres comme armes et comme éléments d'équations mathématiques, il parlait d’ailleurs souvent de l’équation du monde. L'artiste a adopté le nom de Rammellzee, qu'il considérait comme une équation mathématique. À travers le graffiti, le hip-hop, la peinture et la sculpture, Rammellzee a ré-imaginé la trajectoire historique de l'alphabet. Dans un manifeste de 1979 intitulé : Iconic Treatise on Gothic Futurism, l'artiste affirme que les lettres, lorsqu'elles sont séparées de leur fonction littéraire, sont des armes qui peuvent être utilisées pour combattre l'oppression imposée par les systèmes linguistiques contrefaits. Bien que son esthétique puisse sembler ancrée dans la science-fiction, Rammellzee voit un lien fondamental entre l'écriture cryptique des moines du XVIème siècle et sa propre conception de l'alphabet à la fin du XXème siècle. Consumé par sa philosophie, l'artiste insaisissable sortait rarement de son loft de Tribeca, qu'il appelait la “ Battle Station”. Lorsqu'il apparaissait en public, c'était dans une armure futuriste qu'il avait lui-même conçue. Il explique qu’il faut s’armer face à la société. Selon lui, le « graffiti » n’est pas seulement une représentation de la rue pure et simple mais c’est un art qui fait réagir l’intellect.",
  pdp: RammellzeePng,
  alt_pdp: "Photo de profil de Rammellzee.",
  photos: [
    { src: PastPresent1, name: "Rammellzee" },
    { src: PastPresent2, name: "Rammellzee" },
    { src: PastPresent3, name: "Rammellzee" },
  ],
  works: ["Works", "Works", "Works"],
  videos: ["Videos", "Videos", "Videos"],
  citations: []
};

// const exhibitions = [
//   {
//     date: "Avril 2018",
//     title: "DONDISM",
//     link: "rammellzee",
//     artist: "Rammellzee",
//     place: "2 rue de Belloi - 13006 Marseille",
//     description:"Rammellzee - dit DONDI - est l’un des « pères » fondateurs du graffiti « à la New-Yorkaise » des années 70-80 ; Le King du graffiti figuratif New-Yorkais et du lettrage ; Un roi incontesté de mythiques « wholes cars » - comme le fameux children of the grave réalisé en 1980. Son talent graphique lui permit de faire évoluer son esthétisme - passant des rames de métro à la toile pour devenir un des artistes majeurs du XXe siècle.",
//     presentation_photo: "",
//     photos_paths: ["path", "path", "path", "path"],
//   },
// ]
const Rammellzee = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
    <div className={Wrapper}>
     <Helmet>
        {
            isBrowser ? window.onscroll = function(e){
              var sel = document.querySelector('#f')
              var end = document.querySelector('#end').offsetTop - 450;
              if (window.scrollY > end) {
                  sel.style.position = 'absolute';
                  sel.style.top = end.toString()+ "px";
              } else {
                  sel.style.position = 'fixed';
                  sel.style.top = 0;
              }
            } : null
          }
      </Helmet>
      <div className={CardWrapper}>
        <div className={ProfilWrapper} id="f">
          <img className={PdpWrapper} src={infos.pdp} alt={infos.alt_pdp} />
        </div>
        <div className={ArtistDescription}>
          <h1 className={TitleWrapper}>RAMMELLZEE <h2 style={{paddingLeft: "16px"}}>(Américain)</h2></h1>
          <h3 className={NameWrapper} >1960 - 2010</h3>
          <img className={MobileProtrait} src={infos.pdp} alt={infos.alt_pdp} />
          <div className={DescriptionWrapper} dangerouslySetInnerHTML={{__html:infos.description}}></div>
          <div  className={Citations}>
            {infos.citations.map((citations) => (
              <div>
                <p style={{fontStyle: "italic", textAlign: "center" }}>{citations}</p>
                <br />
              </div>
            ))}
          </div>
          <br />
        <ul className={ArtistInfos}>
          <br />
          <p style={{fontWeight: "bolder"}}>EDUCATION</p>
          <br />
          <p>- Clara Barton High School for Health Professions, New York, USA.</p>
          <p>- Fashion Institute of Technology, New York, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>SOLO EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Rammellzee : Gothic Futurism. Jeffrey Deitch, Los Angeles, California, NOV 05, 2022 - JAN 14, 2023</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- RAMM:ELL:ZEE “1985” In Memory of Rammellzee (1960-2010), Galerie Ziegler, Zurich, Switzerland, September 11th - 13th 2020.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- Rammellzee - Racing for Thunder, Red Bull Arts, curated by Maxwell Wolf. New York, USA, May 4th - August 26th.</p>
          <p>- RAMMΣLLZΣΣ: A Roll of Dice, Lazinc Gallery. London, October 2nd - November 10th. </p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Rammellzee: The Equation, The Letter Racers, The Museum of Modern Art, New York, USA, February 19th - May 14th.</p>
          <p>- Rammellzee: The Equation, The Letter Racers, The Suzanne Geiss Company, New York, USA, March 8th - April 21st.</p>
          <p>- The Rammellzee Galaxseum, Children’s Museum of Art, New York, USA, October 4th - February 3rd.</p>
          <div className={More} id="more">
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Ramm:Ell:Zee Gothic Futurism, Galerie Renée Ziegler, Zurich, Switzerland, May 27th - July 3rd.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2005</p>
          <p>- Bi-Conicals of Rammellzee Tour, Venice Biennale, Italy.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1990</p>
          <p>- Rammellzee, Galerie B5/Speerstra Gallery, Monaco, France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- The Equation, Lidia Carrieri Gallery, Rome, Italy. (catalogue)</p>
          <p>- Rammellzee, Groninger Museum, Groningen, The Netherlands, February 7th - March 22nd.</p>
          <p>- Rammellzee, Gemeente Museum Helmond. Helmond, Netherlands, November 15th, 1986 - January 24th, 1987. (catalogue)</p>
          <p>- Rammellzee: The Equation, Galleria Lidia Carrieri. Rome, Italy, 1987. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1986</p>
          <p>- Rammellzee, Gemeente Museum Helmond, Gemeente Museum Helmond, Helmond, Netherlands, 1986. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Rammellzee, Galerie Renée Ziegler, Zürich, Switzerland, April 11th - 14th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Rammellzee: A Special Event, Gothic Futurism, Ikonoklast Panzerism (including a live performance), Annina Nosei Gallery, Soho, New York, USA, February 12th - March 3rd.</p>
          <br />
          <p style={{fontWeight: "bolder"}}>GROUP EXHIBITIONS</p>
          <br />
          <p style={{fontWeight: "bold"}}>2022</p>
          <p>- Haunted Realism ,Gagosian Gallery, London (Grosvenor Hill) ,Mayfair, London, UK</p>
          <p>- New York Street Style : création des années 80 - Ghost Galerie, Paris, juillet - septembre 2022.</p>
          <p>- Jeffrey Deich Booth, Art Basel Miami, Floride, USA</p>
          <br />
          <p style={{fontWeight: "bold"}}>2021</p>
          <p>- Writing the Future : Basquiat and the Hip-Hop Generation, Museum of Fine Arts, Boston, USA, October 18th, 2020 – July 25th, 2021. (catalogue)</p>
          <p>- Ghost DNA - exposition d’ouverture - opening exhibition, Ghost galerie, Paris, France, July 7th - September 4th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2020</p>
          <p>- Crown Point Press in the ’80s, CROWN POINT PRESS GALLERY SAN FRANCISCO, CALIFORNIA, USA, JAN 09,2020 - MAR 07,2020</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Legends, Speerstra Gallery. Paris, April 13th - May 11th.</p>
          <p>- Conquête Urbaine: Street Art au Musée, Musée des Beaux Arts de Calais, Calais, France,</p>
          <p>April 6th - November 3rd. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- ICONS II (with BLADE, Dondi, Futura 2000, Richard Hambleton), Galerie Vroom and Varossieau, Amsterdam, The Netherlands, April 7 th - May 8th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2017</p>
          <p>- Aérobis I Missive’n crew invite ItinéraireBis, Le Musée du Street Art et du Graffiti, L'Aérosol, Maquis-art Hall of Fame, Paris, France, December 30th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2016</p>
          <p>- La Velocità delle immagini (Collection Speerstra), Institut Suisse de Rome, Italy.</p>
          <p>- Graffiti Art - Tableau de légende (Collections Gallizia), Institut culturel Bernard Magrez, Bordeaux, France.</p>
          <p>- Pressionnism, Graffiti Masterpieces on Canvas (Collection Gallizia), Fort Canning Museum, Singapour/ France.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- Le Pressionnisme, de Bando à Basquiat (Collections Gallizia) - La Pinacothèque de Paris. Paris, France, March 12th - October 15th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2014</p>
          <p>- City as Canvas: Graffiti Art (Collection Martin Wong) - Museum of the City of New York, New York, USA, February 4th - July 27th . (catalogue)</p>
          <p>- The Shadows Took Shape, The Studio Museum in Harlem, New York, USA, November 14th, 2013 - March 9th, 2014.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2013</p>
          <p>- Urban Art Biennale 2013, Urban Art Biennale, Völklingen, Germany.</p>
          <p>- Graffiti; Thanks a Lot, Fun Gallery (Curated by Patti Astor), New York, USA.</p>
          <p>- Tekens aan de Wand : Ferenc Gögös/ Graffiti Art, Museum Tongerlohuys, Roosendaal, The Netherlands.</p>
          <p>- White Petals Surround Your Yellow Heart, Institute of Contemporary Art, University of Pennsylvania, Pennsylvania, USA.</p>
          <p>- Last of the Hollywood Africans : Toxic, Londonewcastle Project Space, London, United Kingdom.</p>
          <p>- Abstract Mash-up II : A Group Exhibition, Crown Point Press Gallery, San Francisco, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2012</p>
          <p>- Radical Presence : Black Performance In Contemporary Art, Contemporary Arts Museum Houston, Houston, Texas, USA.</p>
          <p>- God Save the Queen : Punk in the Netherlands 1977 - 1984, Centraal Museum, Utrecht, The Netherlands.</p>
          <p>- Brucennial 2012, Herder. Better. Faster, New York, USA.</p>
          <p>- Strongerer, Brucennial, New York, USA.</p>
          <p>- Speerstra Fondation (Collection Speerstra), Apples, Switzerland.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- L’Art du Graffiti : 40 ans de Pressionnisme, Collection Rammellzee (Curated by A.D. Gallizia), Grimaldi Forum, Monaco, July 21st - August 19th. (catalogue)</p>
          <p>- Art in the Streets, MOCA. Los Angeles, USA, April 17th - August 8th. (catalogue)</p>
          <p>- Perfect Man II, White Columns, New York, USA.</p>
          <p>- Graffiti, New York 80’s, Galerie Jérôme de Noirmont, Paris, France.</p>
          <p>- The Aerosol Experience, Ziegler Zwei, Zurich, Switzerland, November 9th - January 28th.</p>
          <p>- The Rudolf and Ute Scharpff Collection, Kunstmuseum Stuttgart, Germany, February 19th - May 1st.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Street and Studio, Von Basquiat bis Séripop, Kunsthalle Wien, Vienna, Austria. (catalogue)</p>
          <p>- Un musée à ciel ouvert (Collection Gallizia-Emerige), Bâche Wagram, Paris, France.</p>
          <p>- Printin’, The Museum of Modern Art, New York, USA. (catalogue)</p>
          <p>- Né dans la Rue - Graffiti, Fondation Cartier pour l’Art Contemporain, Paris, France, July 7th, 2007 - January 10th, 2010. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- Subcultural Capital, Anonymous Gallery, New York, USA, October 15th - November 15th .</p>
          <p>- The New Yorkers, V1 Gallery, Copehagen, Denmark, May 1st - June 22nd.</p>
          <p>- Tag au Grand Palais (Collections Gallizia), Grand Palais. Paris, March 27th - April 26th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2008</p>
          <p>- Futuro del Futurismo, Gamec, Galleria d’Arte Moderna e Contemporanea di Bergamo, Bergamo, Italy, September 21st 2007 - 24th February 2008.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Graffiti Stories (Collection Speerstra), Abbaye d’Auberive et Musée Paul Valéry, Sète, France.</p>
          <p>- Graffiti Stories (Collection Speerstra), Musée International des Arts Modestes, Sète, France. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- Music is a Better Noise, MoMA PS1. New York, October 29th - January 29th.</p>
          <p>- Sound Zero, Kunst Meran, Merano, Italy. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2004</p>
          <p>- Smile Away the Parties and Champagne, Zeen Keuze uit de collectie, Gemeentemuseum Helmond, Boscotondohal, Helmond, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Americas Remixed, Care Of, Fabbrica del Vapore, Milan, Italy. (catalogue)</p>
          <p>- Cowboys en Kroegtijgers, Gemeentemuseum Helmond, Boscotondohal, Helmond, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2001</p>
          <p>- Oost Duitse Meisjes en Andere Stukken, Gemeentemuseum Helmond, Boscotondohal, Helmond, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1998</p>
          <p>- Smile away the Parties and Champagne, Gemeentemuseum, Helmond, Boscotondohal, Helmond, The Netherlands.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1994</p>
          <p>- Rammellzee vs Gen U One (Gen Atem), Exercises in Self Presentation, Eigen + Art, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- New York Graffiti Art : Coming from the Subway (Collection Speerstra), Groninger Museum, Groningen, The Netherlands, October 4th - January 10th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- American Graffiti : A Survey, Liverpool Gallery, Brussels.</p>
          <p>- Graffiti Art : Artiste américains et français 1981/ 1991(Collection Speerstra), Musée des monuments Français Paris. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1989</p>
          <p>- Hip Hop ‘til You Drop, Whitney Museum of American Art, New York, USA.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987 - 1988</p>
          <p>- Comic iconoclasm : London, June-September 1987; Dublin, Douglas Hyde Gallery, October-November 1987; Manchester, Cornerhouse Gallery January-February 1988 (organized by The Institute of Contemporary Arts, London). (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- Ikonoklast Panzerism, Galerie Renée Ziegler, Zurich, Switzerland.</p>
          <p>- Between Science and Fiction, 18th Bienal de São Paulo. Brasil, October 4th - December 15th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- Rapid Enamel The Art of Graffiti, The Renaissance Society at The University of Chicago, curated by Richard Flood. Chicago, USA, October 7th - November 10th. (catalogue)</p>
          <p>- Ein anderes Klima, a different climate, Kunsthalle Düsseldorf, Düsseldorf, Germany. (catalogue)</p>
          <p>- Graffiti, Groninger Museum. Groningen, The Netherlands, January 14th - February 26th. (catalogue)</p>
          <p>- New York Graffiti (Collection Speerstra), Louisiana Museum, Humlebaek, Denmark, September 7th - October 7th.</p>
          <p>- Graffiti: Artists from New York in Monte-Carlo (opening of the Speerstra Gallery with live performance by Rammellzee), Speerstra Gallery. Monaco, France, April 5th - June 20th.</p>
          <p>- Classical American Graffiti Writers and High Graffiti Artists, Galerie Thomas, Munich, Germany, February 6th - April 5th. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Post-Graffiti, Sidney Janis Gallery. New York, USA, December 1st - 31st. (catalogue)</p>
          <p>- Graffiti, Thanks a Lot, Fun Gallery (curated by Patti Astor), New York, USA.</p>
          <p>- Graffiti, Museum Boijmans, Van Beuningen. Rotterdam, Netherlands, October 22nd - December 4th. (catalogue)</p>
          <p>- Jean-Michel Basquiat / Rammellzee and A-One: Gothic Futurism Sign Overtures Ikonoklast Panzerism, Private residence of Paige Powell, Upper West Side, Manhattan, New York, USA, April 14th.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1982</p>
          <p>- New York, Institute of Contemporary Arts, London, United Kingdom.</p>
          <p>- New York City Rap Tour (with Rammellzee, Futura, PHASE2, DONDI), France: “Grande Nuit du Rap”, L'Hippodrome de Pantin; Palace. Paris, November 27th, and other dates in Paris, Lyon, Metz, Belfort, Mulhouse, Strasbourg, London and Los Angeles.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1981</p>
          <p>- Beyond Words, Mudd Club, New York, USA, April 9th - 24th.</p>
          <p>- Events, Fashion Moda, The New Museum of Contemporary Art. New York, USA, December 14th, 1980 - January 8th, 1981.</p>
          <p>PRIVATE COLLECTIONS</p>
          <p>- Crown Point Press, San Francisco, USA.</p>
          <p>- Galerie Ziegler SA, Zurich Switzerland.</p>
          <p>- Ghost Galerie Marseille, France.</p>
          <p>- FLUCTUART, Centre d’Art Urbain, Paris, France.</p>
          <p>- Museum of Contemporary Art, Los Angeles, USA.</p>
          <br />
          <br />
          <p style={{fontWeight: "bolder"}}>BIBLIOGRAPHIE</p>
          <br />
          <p style={{fontWeight: "bold"}}>2019</p>
          <p>- Conquête Urbaine: Street Art au Musée, LINEART éditions, en partenariat avec le Musée des Beaux-Arts de Calais, Paris 2019. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2018</p>
          <p>- LOKISS, GZELEY N., LEMOINE S., VASLIN J., Graffiti: 50 Ans d'Interactions Urbaines, éditions Hazan, Paris, France, 2018.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2015</p>
          <p>- DANYSZ M., Anthologie du Street Art, Editions Gallimard, Paris, France, 2015.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2011</p>
          <p>- Art in the Streets, edited by N. Columbus, Skira Rizzoli Publications, Inc., New York, USA, in association with The Museum of Contemporary Art, Los Angeles, USA, 2011. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2010</p>
          <p>- Print/Out: 20 Years in Print, texts by Christophe Cherix. With contributions by Kim Conaty and Sarah Suzuki. Published by MOMA, 2012 (catalogue)</p>
          <p>- Street and Studio: From Basquiat To Séripop, Dieter Buchhart with Kunsthalle Wien, Vienna, Austria, 2010 (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2009</p>
          <p>- THOMPSON N., American Graffiti, Parkstone Press International, New York, USA, 2009.</p>
          <br />
          <p style={{fontWeight: "bold"}}>2007</p>
          <p>- Il Futuro del Futurismo, edited by Mondadori Electa in association with Galleria d’Arte Moderna e Contemporanea di Bergamo, Italy, 2007. (catalogue)</p>
          <p>- L’Art Modeste Sous les Bombes, from the exhibition Graffiti Stories : Centre d’art contemporain de l’abbaye d’Auberive, Auberive; Musée Paul Valéry, Sète; Musée International des Arts Modestes, Sète. Edited by Kitchen93, June 15 2007. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2006</p>
          <p>- DEHO, Valerio. Sound Zero. Bologna, Italy: Damiani editore, 2006. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>2002</p>
          <p>- Arratia, Euridice and Budney, Jen and Sirmans, Franklin and Pinto, Roberto. Americas Remixed. Milan, Italy: Artshow Edizioni; s.l.: Silvana Editoriale, 2002.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1992</p>
          <p>- New York Graffiti Art: Coming from the Subway, edited by Benjamin and Partners in association with the Groninger Museum, Groningen, The Netherlands, 1992. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1991</p>
          <p>- Graffiti art : artistes américains et français, 1981-1991, Acte II, le Musée National des Monuments Français, Paris, 1991. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1987</p>
          <p>- Comic iconoclasm, Cornerhouse Gallery, Douglas Hyde Gallery, Institute of Contemporary Arts (authors), edited by London: The Institute, 1987. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1985</p>
          <p>- 18a Bienal Internacional de São Paulo: Catálogo Geral (General catalogue), Fundação Bienal de São Paulo, Brasil, 1985.</p>
          <br />
          <p style={{fontWeight: "bold"}}>1984</p>
          <p>- A Different Climate: Aspects of Beauty in Contemporary Art, Düsseldorf. Städtische Kunsthalle Düsseldorf. Düsseldorf, Germany, 1984. Texts by Jürgen Harten, Giulio Carlo Argan, Friedrich Nicolai, Harald Szeemann. (catalogue)</p>
          <p>- Rapid Enamel: The Art of Graffiti, The Renaissance Society at The University of Chicago, Chicago, USA, 1984. Essay by Richard Flood. (catalogue)</p>
          <br />
          <p style={{fontWeight: "bold"}}>1983</p>
          <p>- Post-Graffiti, Sidney Janis Gallery, New York. December 1-31, 1983. Introduction by Dolores Neumann. (catalogue)</p>
          <p id="end">- Graffiti, Museum Boymans van Beuningen, and Groninger Museum, Rotterdam, Netherlands, 1983. Essay by Edit DeAk. (catalogue)</p>
          </div>
        </ul>
            <button className={MoreButton} id="moreBtn" onClick={() => {
                 var sel = document.querySelector('#more');
                 var selBtn = document.querySelector('#moreBtn');
                 console.log("in there");

                
                  if (sel.style.display === "none") {
                    sel.style.display = "inline";
                    selBtn.innerHTML = "Read more";
                  } else {
                    sel.style.display = "none";
                    selBtn.innerHTML = "Read less";
                }
                 
              }}
               >Read more</button>
        </div>
      </div>
      

      <div className={PhotosWrapper}>
       <Title title={"Past or Present"} />
        <div className={CarrouselWrapper2}>
          {/* <Carrousel images={infos.photos} />
           */}
           <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
        </div>
      </div>
      <div className={ButtonWrapper}>
            <Button
              alt=""
              onClick={() => {
                if (typeof window !== "undefined") {
                  window.location.href = "/fr/contactez-nous";
                }
              }
            }
            >
              Contactez-nous
            </Button>
        </div>
    </div>
  );
};

export default Rammellzee;