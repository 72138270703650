// extracted by mini-css-extract-plugin
export var ArtistDescription = "Rammellzee-module--ArtistDescription--S0W4F";
export var ArtistInfos = "Rammellzee-module--ArtistInfos--cnwiS";
export var ButtonWrapper = "Rammellzee-module--ButtonWrapper --UrAFj";
export var CardWrapper = "Rammellzee-module--CardWrapper--N7MWY";
export var CarrouselWrapper2 = "Rammellzee-module--CarrouselWrapper2--6hPjt";
export var Citations = "Rammellzee-module--Citations--lh7Lv";
export var DescriptionWrapper = "Rammellzee-module--DescriptionWrapper--eiRJN";
export var ImageWrapper = "Rammellzee-module--ImageWrapper--d5+iz";
export var LinkWrapper = "Rammellzee-module--LinkWrapper--McsdE";
export var MobileProtrait = "Rammellzee-module--MobileProtrait--70dvA";
export var More = "Rammellzee-module--More--JW1ZK";
export var MoreButton = "Rammellzee-module--MoreButton--kpfws";
export var NameWrapper = "Rammellzee-module--NameWrapper---wUAw";
export var PdpWrapper = "Rammellzee-module--PdpWrapper--qFGd8";
export var PhotosWrapper = "Rammellzee-module--PhotosWrapper--FViT6";
export var ProfilWrapper = "Rammellzee-module--ProfilWrapper--fIsFy";
export var TitleWrapper = "Rammellzee-module--TitleWrapper--7ZH3E";
export var Wrapper = "Rammellzee-module--Wrapper--4zkH7";